import { ID } from "gql";
import {
  LOGGED_IN_AS_CLIENT_ADVISOR_ID,
  LocalStorage,
} from "shared/utils/local-storage";

export function loggedInAsAdvisorID() {
  return LocalStorage.getItemSync_WebOnly(LOGGED_IN_AS_CLIENT_ADVISOR_ID);
}

export function removeLoggedInAsAdvisorID() {
  LocalStorage.removeItemSync_WebOnly(LOGGED_IN_AS_CLIENT_ADVISOR_ID);
}

export function addLoggedInAsAdvisorID(advisorID: ID) {
  LocalStorage.setItemSync_WebOnly(
    LOGGED_IN_AS_CLIENT_ADVISOR_ID,
    advisorID.toString()
  );
}
