import { useRouter as useNextRouter } from "next/router";
import type { XRouter } from "./types";

export function useRouter(): XRouter {
  const nextRouter = useNextRouter();
  return {
    ...nextRouter,
    webOnlyAsPath: nextRouter.asPath,
    webOnlyReloadPage: () => nextRouter.reload(),
  };
}
