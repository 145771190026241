/**
 * This function is a compile-time check to ensure that a switch statement is exhaustive.
 * It's a no-op function that should never be called in development, but will occasionally be called
 * in production during deployment race conditions.
 *
 * Consider an enum generated from our graphql schema:
 * When we update the graphql schema, we'll have code that deploys to both the client and server.
 * If the server deploys first, the client will start receiving the new enum values before the client
 * has been updated to handle them. Thus, we need to include "default" return statements in our
 * switch statements to handle the new values. However, we often want to ensure that our
 * switch-statements are exhaustive, so we can use this function to ensure that we've handled all
 * possible values while still providing a default case.
 *
 * @param _enumOrStringUnion - a union of string or enum values that should be exhaustive
 */
export default function defaultGuard(_enumOrStringUnion: never) {
  return;
}
// TODO: One day we should have a custom eslint rule to ensure this is used in every switch-case statement
