export enum AuthFactor {
  First,
  Second,
}
type FirstFactorData = {
  type: AuthFactor.First;
  payload: {
    email: string;
    password: string;
  };
};
type SecondFactorData = {
  type: AuthFactor.Second;
  payload: {
    email: string;
    password: string;
    otp_attempt: string;
  };
};

export type LoginData = FirstFactorData | SecondFactorData;
