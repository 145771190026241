const INTERNAL_TEST_EMAILS = [
  "savvy.demo.client@gmail.com", // Allison Chan
  "savvy.demo.client+coclient@gmail.com", // Frank Chan
  "demo-advisor@savvyadvisors.com", // Cooper Hudson
  "heather@savvyadvisors.com", // Heather Lee
];
export const isSavvyTestUserEmail = (email?: string | null): boolean => {
  if (!email) {
    return false;
  }
  if (INTERNAL_TEST_EMAILS.includes(email)) {
    return true;
  }
  return email.endsWith("test@savvywealth.com");
};

export const isEmployeeEmail = (email?: string | null): boolean => {
  if (!email) {
    return false;
  }
  return email.endsWith("savvywealth.com");
};
