import React from "react";
import { useAuth } from "shared/hooks/use-auth";
import { LDProvider } from "launchdarkly-react-client-sdk";
import Chatbot from "./chatbot";
import assert from "assert";

const ChatbotWrapper = () => {
  const { user } = useAuth();
  const clientSideID = process.env["NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID"];
  assert(
    clientSideID != null,
    "NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID is not defined in env"
  );

  return (
    <LDProvider
      clientSideID={clientSideID}
      user={{
        key: user?.id?.toString(),
        name: `${user?.firstName} ${user?.lastName}`,
        email: user?.email,
      }}
    >
      <Chatbot />
    </LDProvider>
  );
};

export default ChatbotWrapper;
