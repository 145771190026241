// TODO: SAV-3878 - Make this work for native apps (using globalThis?)
export const ADVISOR_IMPERSONATE_CLIENT_ID_KEY = "impersonateClientId";
export function setImpersonateClientId(clientId?: string | number) {
  if (clientId) {
    sessionStorage.setItem(
      ADVISOR_IMPERSONATE_CLIENT_ID_KEY,
      clientId.toString()
    );
  } else {
    sessionStorage.removeItem(ADVISOR_IMPERSONATE_CLIENT_ID_KEY);
  }
}

export function getImpersonateClientId() {
  return sessionStorage.getItem(ADVISOR_IMPERSONATE_CLIENT_ID_KEY);
}
