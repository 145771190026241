import { JWTContextUser } from "shared/api/user-api";
import { NextRouter } from "next/router";
import defaultGuard from "shared/utils/default-guard";
import { isSavvyTestUserEmail } from "shared/utils/internal-savvy";
import { XRouter } from "hooks/use-router";

export function getClientStageRedirectRoute(
  router: NextRouter | XRouter,
  user: JWTContextUser | null,
  isSmallScreen: boolean
) {
  const clientStage = user?.clientStage;
  const currentPath = router.basePath + router.pathname;

  // Some dashboard pages are accessible to non-fully onboarded clients
  // Savvy employees can access all pages
  if (
    [
      "/dashboard",
      "/dashboard/advisor",
      "/dashboard/account",
      "/tasks",
      "/portfolio",
    ].includes(currentPath) ||
    currentPath.startsWith("/account-setup") ||
    currentPath.startsWith("/files") ||
    isSavvyTestUserEmail(user?.email)
  ) {
    return null;
  }

  switch (clientStage) {
    case "fully_onboarded":
      if (
        isSmallScreen &&
        user?.personalPortfolioId != null &&
        !currentPath.includes("/savvy-portfolio")
      ) {
        return `/savvy-portfolio/${user.personalPortfolioId}`;
      }
      if (["/welcome", "/transition", "/onboarding"].includes(currentPath)) {
        return "/portfolio";
      }
      return null;
    case "account_setup_flow":
      if (currentPath !== "/account-setup") {
        return "/dashboard";
      }
      return null;
    case "onboarding_flow":
    case "post_onboarding_waiting_area":
      if (currentPath !== "/onboarding") {
        return "/dashboard";
      }
      return null;
    case "intake_flow":
    case "post_intake_waiting_area":
      if (currentPath !== "/welcome") {
        return "/dashboard";
      }
      return null;
    case null:
    case undefined:
      return null;
    default:
      defaultGuard(clientStage);
      return null;
  }
}
