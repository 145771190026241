import { useTheme } from "shared/hooks/use-theme";
import { greyIconsColor, textColors } from "./theme";
import { IconColor, TextColorDefaults, WebStyle, XStyle } from "./types";

export const mapIconColorToPalette: Record<IconColor | string, string> = {
  // TODO: Implement these colors seriously
  action: greyIconsColor,
  disabled: greyIconsColor,
  error: greyIconsColor,
  info: greyIconsColor,
  inherit: greyIconsColor,
  primary: greyIconsColor,
  secondary: greyIconsColor,
  success: greyIconsColor,
  warning: greyIconsColor,
};

export const mapTextColorToPalette: Record<TextColorDefaults | string, string> =
  {
    textCharcoal: textColors.charcoal,
    textDisabled: textColors.disabled,
    textPrimary: textColors.primary,
    textSecondary: textColors.secondary,
  };

export function convertPxStringToNumber(pxString: string): number {
  return Number(pxString.replace("px", ""));
}

export function useModifiedStyleForWeb(
  style: XStyle | null | undefined
): WebStyle | undefined {
  const { direction } = useTheme();
  if (style == null) {
    return undefined;
  }
  return {
    ...style,
    left: direction === "ltr" ? style.start : style.end,
    marginLeft: direction === "ltr" ? style.marginStart : style.marginEnd,
    marginRight: direction === "ltr" ? style.marginEnd : style.marginStart,
    paddingLeft: direction === "ltr" ? style.paddingStart : style.paddingEnd,
    paddingRight: direction === "ltr" ? style.paddingEnd : style.paddingStart,
    right: direction === "ltr" ? style.end : style.start,
  };
}
