import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { LDProps } from "launchdarkly-react-client-sdk/src/withLDConsumer";
import { useEffect } from "react";

const modifyChatBubbleClasses = () => {
  const danteBubbles = document.querySelectorAll(".dante-embed-icon");
  danteBubbles.forEach((bubble) => {
    bubble.classList.add("dante-bubble-modifications");
    bubble.classList.remove("dante-skeleton");
  });
};

const Chatbot = ({ flags }: LDProps) => {
  useEffect(() => {
    if (!flags?.["chatbot"]) return;

    const loadScript = (src: string) => {
      const script = document.createElement("script");
      script.src = src;
      script.defer = true;
      script.onload = modifyChatBubbleClasses;
      document.head.appendChild(script);
    };

    window.danteEmbed =
      "https://chat.dante-ai.com/embed?kb_id=b593913e-df11-43c2-8ae4-108d38dba93c&token=ed25a09a-f979-409c-b9cd-463aba0142cb&modeltype=gpt-4&mode=undefined&bubble=true&image=https://dante-chatbot-pictures.s3.amazonaws.com/b593913e-df11-43c2-8ae4-108d38dba93c/Savvy%20logo2.png&bubbleopen=false";

    try {
      loadScript("https://chat.dante-ai.com/bubble-embed.js");
    } catch (error) {
      // Dante is pretty unreliable and there's not much we can do if it fails
      // to load, so we swallow the error and move on
      console.error("Error loading chatbot script: ", error);
    }
  }, [flags]);

  return (
    <>
      {/* z-index: 1049 places it below MUI fab, speed dial, drawer, app bar, modal, snackbar, and tooltip components */}
      <style>{`
        .dante-bubble-modifications {
          z-index: 1049 !important;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px 3px !important;
        }
      `}</style>
    </>
  );
};

export default withLDConsumer()(Chatbot);
